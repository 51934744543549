import { object, oneOfType, string } from 'prop-types'
import PageLoading from '../components/Dumb/PageLoading/PageLoading'
import MaintenancePage from '../pages/Maintenance/Maintenance'
import { useEffect, useState } from 'react'
import axios from 'axios'

export default function AdminMaintenance({ children }) {
    const [maintenance, setMaintenance] = useState(null)

    useEffect(() => {
        axios.get('/api/admin/maintenance').then(response => setMaintenance(!response.data.data.allowed))
    }, [])

    if (maintenance === null) {
        return <PageLoading />
    }

    if (maintenance) {
        return <MaintenancePage />
    }

    return children
}

AdminMaintenance.propTypes = {
    children: oneOfType([string, object]),
}
