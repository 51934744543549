import { createSlice } from '@reduxjs/toolkit'

export const popinInformationSlice = createSlice({
    name: 'popinInformation',
    initialState: {
        popins: [],
    },
    reducers: {
        setPopins: (state, action) => {
            state.popins = action.payload ?? []
        },
        removePopin: (state, action) => {
            state.popins = state.popins.filter(popin => popin.id !== action.payload.id)
        },
    },
})

export const { setPopins, removePopin } = popinInformationSlice.actions

export default popinInformationSlice.reducer
