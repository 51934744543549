import classNames from 'classnames'
import { number, oneOfType } from 'prop-types'
import { bool, func, string } from 'prop-types'
import './Checkbox.css'

const Checkbox = function ({ isChecked, setIsChecked, label, disabled, customClass, number, required }) {
    return (
        <div
            className={classNames('checkbox flex relative', {
                disabled: disabled,
                'cursor-pointer': !disabled,
                'is-checked': isChecked,
                [customClass]: customClass,
            })}
            onClick={() => {
                if (!disabled) {
                    setIsChecked(!isChecked)
                }
            }}
        >
            <input
                className={`absolute left-0 pointer-events-none`}
                checked={isChecked}
                type='checkbox'
                disabled={disabled}
                readOnly={!required}
                required={required}
            />

            <div
                className={`checkmark cursor-pointer ${number ? 'checkmark-number' : ''}`}
                onClick={() => {
                    if (!disabled) {
                        setIsChecked(!isChecked)
                    }
                }}
            >
                {number ? number : ''}
            </div>

            <label className={`uppercase ${disabled ? 'disabled text-iseki-grey-400' : ''} `}>
                {label && <span>{label}</span>}
            </label>
        </div>
    )
}
Checkbox.propTypes = {
    /**
     * Refresh parent function
     */
    setIsChecked: func,
    /**
     * isChecked
     */
    isChecked: oneOfType([bool, number]),
    /**
     * Label to display beside the checkbox
     */
    label: string,
    /**
     * Disabled the checkbox
     */
    disabled: bool,
    required: bool,
    customClass: oneOfType([bool, string]),
    number: number,
}
Checkbox.displayName = 'Checkbox'
Checkbox.defaultProps = {
    isChecked: false,
    disabled: false,
    required: false,
}

export default Checkbox
