import React from 'react'
import LoginLayout from '../../components/Layout/LoginLayout/LoginLayout'
import { useTranslation } from 'react-i18next'
import PopinDisplayMiddleware from '../../midlewares/PopinDisplayMiddleware'

export default function Maintenance() {
    const { t } = useTranslation()

    return (
        <PopinDisplayMiddleware>
            <LoginLayout>
                <form>
                    <div className='font-bold text-xl uppercase'>{t('messages.maintenance.title')}</div>

                    <div className='mt-3 text-iseki-grey-600 text-sm'>{t('messages.maintenance.description')}</div>
                </form>
            </LoginLayout>
        </PopinDisplayMiddleware>
    )
}
