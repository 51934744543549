import { useDispatch, useSelector } from 'react-redux'
import { removeAlert } from '../../../stores/slices/alerts'

export default function AlertsList() {
    const alerts = useSelector(state => state.alerts.alerts)

    const dispatch = useDispatch()

    return (
        <div className='z-50 fixed bottom-0 w-full text-white text-center'>
            {alerts.map(alert => (
                <div
                    key={alert.id}
                    onClick={() => dispatch(removeAlert(alert.id))}
                    className={'p-4 cursor-pointer shadow ' + (alert.type === 'error' ? 'bg-red-800' : 'bg-green-600')}
                >
                    <div className={'absolute top-1 right-1'}>X</div>

                    <span dangerouslySetInnerHTML={{ __html: alert.message }}></span>
                </div>
            ))}
        </div>
    )
}
