import { useState } from 'react'

const useDebounce = (method, wait) =>
    useState(() => {
        let timeout = null

        return (...args) => {
            clearTimeout(timeout)

            const exec = () => {
                method(...args)
            }

            timeout = setTimeout(exec, wait)
        }
    })

export { useDebounce }
