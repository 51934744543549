import { createSlice } from '@reduxjs/toolkit'

export const cartTypeSlice = createSlice({
    name: 'cartType',
    initialState: {
        cartTypes: { panier: 1, panier_catalogue: 6, panier_cdba: 7 },
    },
})

export default cartTypeSlice.reducer
