import { createSlice } from '@reduxjs/toolkit'
import { localStorageRemember, localStorageSet } from '../../services/localStorage'
import axios from 'axios'

const language = localStorageRemember('current_language', {
    id: 1,
    code: 'fr',
    full_code: 'fr_FR',
    name: 'français',
})

export const languageSlice = createSlice({
    name: 'language',
    initialState: { language, languagePersisted: false, languages: [], countries: [] },
    reducers: {
        setLanguage: (state, action) => {
            state.language = localStorageSet('current_language', action.payload)
            state.languagePersisted = true
        },
        setLanguages: (state, action) => {
            state.languages = action.payload
        },
        setCountries: (state, action) => {
            state.countries = action.payload
        },
    },
})

export const { setLanguage, setLanguages, setCountries } = languageSlice.actions

export const fetchLanguages = () => dispatch => {
    axios.get('/api/languages').then(res => dispatch(setLanguages(res.data.data)))
}

export const fetchCountries = () => dispatch => {
    axios.get('/api/admin/countries').then(res => dispatch(setCountries(res.data.data)))
}

export default languageSlice.reducer
