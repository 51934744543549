import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        currentUser: null,
        userFetched: false,
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.userFetched = true
            state.currentUser = action.payload
        },
        setUserFetched: state => {
            state.userFetched = true
        },
    },
})

export const { setCurrentUser, setUserFetched } = adminSlice.actions

export const fetchCurrentUser = () => dispatch => {
    return axios
        .get('/api/admin/user')
        .then(response => {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token
            dispatch(setCurrentUser(response.data.data))
        })
        .finally(() => dispatch(setUserFetched()))
}

export const logAdminUser = (username, password) => dispatch => {
    return axios
        .post('/api/admin/login', { username, password })
        .then(response => {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token
            dispatch(setCurrentUser(response.data.data))
        })
        .finally(() => dispatch(setUserFetched()))
}

export const logOutAdmin = () => {
    return axios.get('/api/admin/logout').then(() => window.location.reload())
}

export default adminSlice.reducer

export const isGranted = attribute => state => {
    return (
        state.admin.currentUser &&
        (state.admin.currentUser.roles.includes('ROLE_ADMIN') ||
            state.admin.currentUser.droits.find(droit => droit.slug === attribute) !== undefined)
    )
}
