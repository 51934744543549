import { bool, func, node, oneOf, oneOfType, string } from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import './Button.css'
import { useTranslation } from 'react-i18next'

export default function Button({ text, onClick, disabled, className, size, color, full, isLoading, type }) {
    const { t } = useTranslation()
    return (
        <button
            type={type ? type : 'button'}
            onClick={() => onClick()}
            className={classNames(className, {
                button: true,
                extraTiny: size === 'extraTiny',
                'w-full': full,
                'min-h-[2.5rem]': size === 'tiny',
                'min-h-[3rem]': size === 'tinyL',
                'min-h-[3.5rem]': size === 'regular',
                'w-1/4': size === 'quarter',
                'text-white bg-iseki-blue-400 hover:bg-iseki-blue-500': color === 'blue-400' && !disabled,
                'text-iseki-blue-600 bg-white hover:bg-opacity-70': color === 'white' && !disabled,
                'text-iseki-blue-400 bg-iseki-blue-400/20': color === 'blue-400/20' && !disabled,
                'text-iseki-blue-400 bg-white hover:bg-iseki-blue-400 hover:text-white':
                    color === 'white-blue-400' && !disabled,
                'text-white bg-iseki-blue-300': color === 'blue-300' && !disabled,
                'text-white bg-iseki-red-300': color === 'red-300' && !disabled,
                'text-white bg-iseki-grey-400': disabled,
            })}
            disabled={disabled || isLoading}
        >
            {isLoading ? (
                <>
                    <svg
                        className={classNames('animate-spin -ml-1 text-white right-3 lg:right-0 lg:relative', {
                            'h-3 w-3 sm:h-5 sm:w-5 mr-3': size !== 'extraTiny',
                            'h-3 w-3 mr-1': size === 'extraTiny',
                            'text-white ': (color === 'blue-400' || color === 'blue-300') && !disabled,
                            'text-iseki-blue-400': (color === 'white' || color === 'blue-400/20') && !disabled,
                            'text-white': disabled,
                        })}
                        // 'animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                    >
                        <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                        ></circle>
                        <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        ></path>
                    </svg>
                    {t('messages.global.loading')}
                </>
            ) : (
                text
            )}
        </button>
    )
}

Button.propTypes = {
    /**
     * Button label, can be node or string
     */
    text: oneOfType([string, node]),
    /**
     * Button action
     */
    onClick: func,
    /**
     * Is button clickable ?
     */
    disabled: bool,
    /**
     * Custom style
     */
    className: string,
    /**
     * Display loader ?
     */
    isLoading: bool,
    /**
     * Button background color, one of the following
     */
    color: oneOf(['blue-400', 'blue-400/20', 'blue-300', 'white', 'white-blue-400']),
    /**
     * Button height : 56, 40 or 24px
     */
    size: oneOf(['regular', 'tinyL', 'tiny', 'extraTiny']),
    /**
     * Width 100% ?
     */
    full: bool,
    /**
     * Button type button or submit
     */
    type: oneOf(['', 'button', 'submit']),
}

Button.defaultProps = {
    text: 'button label',
    type: '',
    onClick: () => {},
    disabled: false,
    className: '',
    isLoading: false,
    color: 'blue-400',
    size: 'regular',
    full: false,
}
