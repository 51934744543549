import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        currentCartId: null,
        cartFetched: false,
        minicartOpen: false,
    },
    reducers: {
        setCurrentCart: (state, action) => {
            state.cartFetched = true
            state.currentCartId = action.payload
        },
        setMinicartOpen: (state, action) => {
            state.minicartOpen = action.payload
        },
        setCartFetched: state => {
            state.cartFetched = true
        },
    },
})

export const { setCurrentCart, setMinicartOpen } = cartSlice.actions

export default cartSlice.reducer
