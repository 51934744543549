import classNames from 'classnames'
import { bool, func, string } from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../Button/Button'
import Input from '../Input/Input'
import Popin from '../Popin/Popin'
import './SectionTitle.css'

const SectionTitle = forwardRef(
    ({ title, subtitle, editSubtitle, editSubtitleTitle, status, customClass, clickableSubtitle }, ref) => {
        const [showPopin, setShowPopin] = useState(false)
        const [editValue, setEditValue] = useState(null)
        const [editError, setEditError] = useState(null)
        const [loading, setLoading] = useState(false)
        const { t } = useTranslation()

        const subtitleEdition = () => {
            setLoading(true)
            // axios call
            editSubtitle(editValue)
        }

        useEffect(() => {
            setLoading(status)
            setShowPopin(status)
        }, [status])

        useEffect(() => {
            setEditValue(subtitle)
        }, [subtitle])

        return (
            <div className={classNames(customClass, { sectionTitle: true })} ref={ref}>
                <div className='sm:flex'>
                    <div className='title whitespace-pre-line'>{title}</div>
                    {subtitle && (
                        <div
                            className={classNames('pl-5 self-end font-bold lg:text-lg', {
                                'cursor-pointer': clickableSubtitle && editSubtitle,
                            })}
                            onClick={() => {
                                if (clickableSubtitle && editSubtitle) setShowPopin(true)
                            }}
                        >
                            {subtitle}
                        </div>
                    )}
                </div>

                {editSubtitle && (
                    <>
                        <img
                            className='h-10 w-10 ml-4 cursor-pointer'
                            src='/assets/icons/edit.svg'
                            onClick={() => setShowPopin(true)}
                        />

                        <Popin open={showPopin} setOpen={setShowPopin}>
                            <div className='py-5'>
                                <div className='flex flex-col'>
                                    <div className='w-full'>
                                        <h2 className='pb-4 whitespace-normal'>
                                            {editSubtitleTitle ?? t('messages.form.global.edit')}
                                        </h2>
                                    </div>

                                    <div className='mb-8'>
                                        <Input
                                            type='text'
                                            value={editValue}
                                            setValue={value => setEditValue(value)}
                                            error={editError}
                                            setError={() => setEditError(0)}
                                            inputSubtitle={editError}
                                            placeholder={subtitle}
                                            onKeyPress={ev => {
                                                if (ev.code === 'Enter') {
                                                    subtitleEdition()
                                                }
                                            }}
                                            required
                                            full
                                            noMargin
                                        />
                                    </div>

                                    <Button
                                        text={t('messages.form.global.save')}
                                        onClick={subtitleEdition}
                                        color='blue-400'
                                        isLoading={loading}
                                    />
                                </div>
                            </div>
                        </Popin>
                    </>
                )}
            </div>
        )
    },
)

SectionTitle.propTypes = {
    /**
     * Title
     */
    title: string,
    /**
     * Subtitle
     */
    subtitle: string,
    /**
     * Edit subtitle function (api call)
     */
    editSubtitle: func,
    editSubtitleTitle: string,
    /**
     *
     */
    status: bool,
    customClass: string,
    clickableSubtitle: bool,
}

SectionTitle.defaultProps = {
    customClass: '',
}

SectionTitle.displayName = 'SectionTitle'

export default SectionTitle
