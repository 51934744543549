import { bool, func, number, oneOf, oneOfType, string } from 'prop-types'
import React, { useEffect } from 'react'
import classNames from 'classnames'

export default function Input({
    type,
    label,
    inputSubtitle,
    placeholder,
    value,
    curr,
    setValue,
    error,
    setError,
    required,
    validationAction,
    validationSuccess,
    tiny,
    labelSize,
    labelUppercase,
    customClass,
    innerLogo,
    innerLogoAction,
    noBorder,
    full,
    infoButton,
    noMargin,
    onFocus,
    onBlur,
    min,
    max,
    onKeyPress,
    disabled,
    inputHolderMargin,
    maxLength,
    inputWidth,
    rigthText,
    flex,
}) {
    useEffect(() => {
        if (error && setError) {
            setError()
        }
    }, [value])

    return (
        <div
            className={classNames({
                inputHolder: inputHolderMargin,
                'w-full': full,
                'text-iseki-red-400': error,
                'text-iseki-grey-600': !error,
                '!m-0': noMargin,
                'text-iseki-grey-400': disabled,
                flex: flex,
                [inputWidth]: inputWidth,
            })}
        >
            {label && (
                <label
                    className={classNames({
                        'text-xs': labelSize === 'small',
                        uppercase: labelUppercase === 'uppercase',
                        'mr-16': 'validationAction',
                    })}
                >
                    {required ? label + ' *' : label}
                </label>
            )}
            <input
                type={type === 'price' ? 'number' : type}
                min={type === 'number' || type === 'price' ? min : undefined}
                max={type === 'number' || type === 'price' ? max : undefined}
                maxLength={maxLength ?? '250'}
                value={type === 'price' ? Math.round(parseFloat(value) * 100) / 100 : value ?? ''}
                step={type === 'price' ? 0.01 : null}
                onInput={e => setValue(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyPress}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                className={classNames(customClass, {
                    'placeholder:text-sm': true,
                    'border-iseki-grey-550 placeholder:text-iseki-grey-400': !error,
                    'border-none': noBorder,
                    'border-iseki-red-400 placeholder:text-iseki-red-400': error,
                    'h-10': tiny,
                    'h-14': !tiny,
                    'pr-2': type === 'number' || (type === 'price' && curr?.length <= 2),
                    'bg-white p-3': type === 'color',
                    'appearance-none': type === 'price',
                })}
            />
            {innerLogo && (
                <img
                    className={classNames('absolute right-3.5 w-4 h-4', {
                        'cursor-pointer': innerLogoAction,
                        '-translate-y-[11px]': inputSubtitle,
                    })}
                    src={innerLogo}
                    onClick={innerLogoAction}
                />
            )}
            {type === 'price' && (
                <div
                    className={`absolute text-iseki-grey-500 w-3.5 h-5 pointer-events-none ${
                        curr?.length > 2 ? 'right-3 text-2xs' : 'right-1.5'
                    } `}
                >
                    {curr ?? '€'}
                </div>
            )}
            {rigthText && (
                <div
                    className={classNames('absolute right-2', {
                        '-translate-y-[11px]': inputSubtitle,
                    })}
                >
                    {rigthText}
                </div>
            )}
            {validationAction && (
                <button
                    onClick={event => {
                        event.preventDefault()
                        validationAction()
                    }}
                    type='button'
                    className={classNames(
                        'flex self-center ml-5 items-center justify-center rounded-full h-7 w-7 transition-all',
                        {
                            'bg-green-500': validationSuccess,
                            'bg-iseki-blue-300 hover:bg-iseki-blue-500': !validationSuccess,
                            'text-white text-sm': infoButton,
                        },
                    )}
                >
                    {infoButton ? 'i' : <img alt='check icon' src='/assets/icons/check.svg' className='h-2 w-3' />}
                </button>
            )}
            {inputSubtitle && (
                <div className={error ? 'inputSubtitle text-iseki-red-400' : 'inputSubtitle'}>{inputSubtitle}</div>
            )}
        </div>
    )
}

Input.propTypes = {
    /**
     * Input type
     */
    type: string,
    /**
     * Input label (not required)
     */
    label: string,
    /**
     * Input placeholder (not required)
     */
    placeholder: string,
    /**
     * Input value
     */
    value: oneOfType([string, number]),
    /**
     * Input set value function
     */
    setValue: func,
    /**
     * Is input required ?
     */
    required: bool,
    /**
     * Is in error ?
     */
    error: oneOfType([string, number, bool]),
    /**
     * Set error function to remove error when value is changed
     */
    setError: func,
    /**
     * Input subtitle (can be error message)
     */
    inputSubtitle: oneOfType([string, number, bool]),
    /**
     * Function to execute when click on right validation button (not required)
     */
    validationAction: oneOfType([bool, func]),
    /**
     * Successful validation action ?
     */
    validationSuccess: bool,
    /**
     * Input height to 40px
     */
    tiny: bool,
    /**
     * Label font-size
     */
    labelSize: oneOf(['regular', 'small']),
    /**
     * Label uppercase
     */
    labelUppercase: string,
    /**
     * Custom style
     */
    customClass: string,
    /**
     * Input inner logo src
     */
    innerLogo: string,
    curr: string,
    /**
     * Input inner logo action (not required)
     */
    innerLogoAction: func,
    /**
     * Input border none ?
     */
    noBorder: bool,
    /**
     * Input width 100% ?
     */
    full: bool,
    /**
     * Validation button with 'i' instead of check (must have a validation action)
     */
    infoButton: bool,
    /**
     * Input margin: 0 ?
     */
    noMargin: bool,
    /**
     * Input onFocus function
     */
    onFocus: func,
    /**
     * Input onBlur function
     */
    onBlur: func,
    disabled: bool,
    inputHolderMargin: bool,
    min: oneOfType([number, string]),
    max: oneOfType([number, string]),
    maxLength: number,
    /**
     * Input onKeyPress function
     */
    onKeyPress: func,
    inputWidth: oneOfType([bool, string]),
    rigthText: oneOfType([bool, string]),
    /**
     * Flex on div containing input
     */
    flex: oneOfType([bool, string]),
}

Input.defaultProps = {
    labelSize: 'regular',
    customClass: '',
    placeholder: '',
    inputHolderMargin: true,
}
