import { createSlice } from '@reduxjs/toolkit'

let increment = 0

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {
        alerts: [],
    },
    reducers: {
        addAlert: (state, action) => {
            // state.alerts.push({ id: ++increment, ...action.payload })
            state.alerts = [{ id: ++increment, ...action.payload }]
        },
        removeAlert: state => {
            // state.alerts = state.alerts.filter(alert => alert.id !== action.payload)
            state.alerts = []
        },
    },
})

export const { addAlert, removeAlert } = alertsSlice.actions

export const makeAlert = (type, message, duration) => dispatch => {
    dispatch(addAlert({ type, message }))

    setTimeout(() => dispatch(removeAlert(increment)), duration || 15000)
}

export const alertSuccess = (message, duration) => dispatch => makeAlert('success', message, duration)(dispatch)
export const alertError = (message, duration) => dispatch => makeAlert('error', message, duration)(dispatch)

export default alertsSlice.reducer
