import { createSlice } from '@reduxjs/toolkit'

export const popinConfirmSlice = createSlice({
    name: 'confirmPopin',
    initialState: {
        confirmMessage: null,
    },
    reducers: {
        setConfirmMessage: (state, action) => {
            state.confirmMessage = action.payload
        },
    },
})

const { setConfirmMessage } = popinConfirmSlice.actions

export let responseCallBack = () => {}

export const confirmThat = (message, noCallback) => dispatch => {
    dispatch(setConfirmMessage(message))

    return new Promise((resolve, reject) => {
        responseCallBack = response => {
            dispatch(setConfirmMessage(null))
            responseCallBack = () => {}

            if (response) {
                resolve()
            } else {
                if (noCallback) {
                    noCallback()
                }
                reject()
            }
        }
    })
}

export default popinConfirmSlice.reducer
