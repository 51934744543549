import { useContext, useEffect } from 'react'
import { object, oneOfType, string } from 'prop-types'
import { PopinContext } from '../../../contexts/PopinContext'

export default function PopinManager({ children }) {
    const [displayPopin, hidePopin] = useContext(PopinContext)

    useEffect(() => {
        displayPopin(children)

        return () => hidePopin(children)
    }, [children])
}

PopinManager.propTypes = {
    children: oneOfType([string, object]),
}
