// React and modules
import { Route, Routes } from 'react-router-dom'
import React from 'react'
// Config
// import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageLoading from './components/Dumb/PageLoading/PageLoading'
import Maintenance from './midlewares/Maintenance'
import AdminMaintenance from './midlewares/AdminMaintenance'
import FrontEndMiddleware from './midlewares/FrontEndMiddleware'
import PopinDisplayMiddleware from './midlewares/PopinDisplayMiddleware'

const RoutesFrontEnd = React.lazy(() => import('./routes/RoutesFrontEnd'))
const RoutesBackEnd = React.lazy(() => import('./routes/RoutesBackEnd'))

export default function Router() {
    // const { pathname } = useLocation()
    //todo check with api for maintenance status

    return (
        // <TransitionGroup>
        //     <CSSTransition key={pathname} classNames='fade' timeout={300}>
        <Routes>
            <Route
                path='/admin/*'
                element={
                    <AdminMaintenance>
                        <PopinDisplayMiddleware>
                            <React.Suspense fallback={<PageLoading />}>
                                <RoutesBackEnd />
                            </React.Suspense>
                        </PopinDisplayMiddleware>
                    </AdminMaintenance>
                }
            />

            <Route
                path='/*'
                element={
                    <Maintenance>
                        <FrontEndMiddleware>
                            <PopinDisplayMiddleware>
                                <React.Suspense fallback={<PageLoading />}>
                                    <RoutesFrontEnd />
                                </React.Suspense>
                            </PopinDisplayMiddleware>
                        </FrontEndMiddleware>
                    </Maintenance>
                }
            />
        </Routes>

        // </CSSTransition>
        // </TransitionGroup>
    )
}
