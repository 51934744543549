import { object, oneOfType, string } from 'prop-types'
import { useMemo, useState } from 'react'
import { PopinContext } from '../contexts/PopinContext'
import ConfirmPopin from '../components/Dumb/ConfirmPopin/ConfirmPopin'

export default function PopinDisplayMiddleware({ children }) {
    const [popins, setPopins] = useState([])

    const value = useMemo(() => {
        const displayPopin = popin => {
            const index = popins.findIndex(child => child._owner.flags !== popin._owner.flags)

            if (index === -1) {
                setPopins(prev => [...prev, popin])
            } else {
                const newPopins = [...popins]
                newPopins[index] = popin
                setPopins(newPopins)
            }
        }
        const hidePopin = popin => {
            setPopins(popins.filter(child => child._owner?.flags !== popin._owner?.flags))
        }

        return [displayPopin, hidePopin]
    }, [])

    return (
        <PopinContext.Provider value={value}>
            {children}

            <ConfirmPopin />

            {popins}
        </PopinContext.Provider>
    )
}

PopinDisplayMiddleware.propTypes = {
    children: oneOfType([string, object]),
}
