import { createSlice } from '@reduxjs/toolkit'

export const footerSlice = createSlice({
    name: 'footer',
    initialState: {
        content: null,
    },
    reducers: {
        setFooter: (state, action) => {
            state.content = action.payload
        },
    },
})

export const { setFooter } = footerSlice.actions

export default footerSlice.reducer
