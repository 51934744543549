import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true

const anonymousResources = ['/api/admin/login', '/api/admin/user', '/api/customer', '/api/customer/login']

axios.interceptors.response.use(undefined, err => {
    return new Promise(() => {
        if (err.response?.status === 503) {
            document.location.reload()
        }

        if (err.response?.status === 401 && !anonymousResources.includes(err.response.config.url)) {
            document.location.reload()
        }

        throw err
    })
})
