import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useEffect } from 'react'
import { fetchCountries, fetchLanguages, setLanguage } from '../stores/slices/language'
import { useDebounce } from '../customHooks/useDebounce'

export function useLanguages() {
    const languages = useSelector(state => state.language.languages)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!languages.length) {
            dispatch(fetchLanguages())
        }
    }, [])

    return languages
}

export function useCountries() {
    const countries = useSelector(state => state.language.countries)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!countries.length) {
            dispatch(fetchCountries())
        }
    }, [])

    return countries
}

export function useLanguageSelector() {
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const language = useSelector(state => state.language.language)
    const languages = useSelector(state => state.language.languages)
    const languagePersisted = useSelector(state => state.language.languagePersisted)
    const switchLanguage = newLanguage => {
        axios.post('/api/languages/switch', { locale: newLanguage.code }).then(() => {
            dispatch(setLanguage(newLanguage))
            dispatch(fetchLanguages())
            i18n.changeLanguage(newLanguage.code)
        })
    }

    const [debounceSwitch] = useDebounce(switchLanguage)

    useEffect(() => {
        if (!languagePersisted) {
            debounceSwitch(language)
        }
    }, [])

    return [language, debounceSwitch, languages]
}
