import classNames from 'classnames'
import { oneOfType } from 'prop-types'
import { node, bool, func, string } from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickOutside } from '../../../services/clickOutside'
import PopinManager from './PopinManager'

export default function Popin({ children, open, setOpen, additionalClasses, customXlWidth }) {
    const popinRef = useRef(null)
    const { t } = useTranslation()

    useClickOutside(popinRef, () => setOpen(false))

    return (
        <>
            {open && (
                <PopinManager>
                    <div className='w-screen h-screen bg-black bg-opacity-20 fixed flex items-center justify-center top-0 left-0 z-50'>
                        <div
                            className={classNames('bg-white w-11/12 md:w-2/3  p-8 rounded-md relative', {
                                [customXlWidth]: customXlWidth,
                                'xl:w-auto': !customXlWidth,
                                [additionalClasses]: additionalClasses,
                            })}
                            ref={popinRef}
                        >
                            <button
                                onClick={e => {
                                    e.stopPropagation()
                                    setOpen(false)
                                }}
                                className={'absolute right-0 top-0 m-2 !filter-none'}
                            >
                                <img
                                    src='/assets/icons/close.svg'
                                    title={t('messages.global.close')}
                                    alt='close'
                                    className='w-7'
                                />
                            </button>

                            {children}
                        </div>
                    </div>
                </PopinManager>
            )}
        </>
    )
}

Popin.propTypes = {
    /**
     * Popin content
     * <Popin>{children}</popin>
     */
    children: node,
    /**
     * Is popin opened ?
     */
    open: bool,
    /**
     * Set popin opened status function
     */
    setOpen: func,

    additionalClasses: string,
    customXlWidth: oneOfType([bool, string]),
}
