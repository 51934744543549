import axios from 'axios'

window.onerror = function (message, source, line, col, error) {
    axios
        .post('/api/log/error', {
            url: document.location.href,
            message,
            source,
            line,
            col,
            error,
            userAgent: navigator.userAgent,
        })
        .catch()
}
