import { object, oneOfType, string } from 'prop-types'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function FrontEndMiddleware({ children }) {
    const { pathname } = useLocation()

    useEffect(() => {
        scrollTo(0, 0)
    }, [pathname])

    return children
}

FrontEndMiddleware.propTypes = {
    children: oneOfType([string, object]),
}
