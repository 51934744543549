import React from 'react'
import LanguageSelector from '../../Smart/LanguageSelector/LanguageSelector'
import { element } from 'prop-types'
import './LoginLayout.css'
import { useTranslation } from 'react-i18next'

export default function LoginLayout({ children }) {
    const { t } = useTranslation()

    return (
        <div className='userLogin'>
            <div className='w-full flex justify-end pr-9 py-8'>
                <LanguageSelector />
            </div>

            <div className='login'>
                <div className='logoSlogan'>
                    <img
                        className='hidden sm:block h-52 pl-5 self-end'
                        src='/assets/images/sheep.png'
                        alt='Logo MYISEKI'
                    />
                    <div className='sloganWrapper'>
                        <img src='/assets/logos/logo-myiseki.svg' alt='Logo MYISEKI' />
                        <div className='slogan'>{t('messages.login.title')}</div>
                    </div>
                </div>

                {children}
            </div>

            <div className='text-white text-sm text-center mt-6'>
                {t('messages.login.connectionProblem')}
                <br />
                <a className='link-small-white' href='mailto: webmaster@iseki.fr'>
                    webmaster@iseki.fr
                </a>
            </div>

            <div className='copyright'>{t('messages.login.copyright')}</div>
        </div>
    )
}

LoginLayout.propTypes = {
    children: element,
}
