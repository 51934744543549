import Popin from '../Popin/Popin'
import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import { responseCallBack } from '../../../stores/slices/confirmPopin'

export default function ConfirmPopin() {
    const confirmMessage = useSelector(state => state.confirmPopin.confirmMessage)

    return confirmMessage ? (
        <Popin open={!!confirmMessage} setOpen={() => responseCallBack(false)}>
            {confirmMessage}

            <div className='flex justify-end mt-6'>
                <Button onClick={() => responseCallBack(true)} text='Oui' size='tiny' className='mr-2' />
                <Button onClick={() => responseCallBack(false)} text='Annuler' size='tiny' color='blue-400/20' />
            </div>
        </Popin>
    ) : (
        <></>
    )
}
