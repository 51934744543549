import { BrowserRouter } from 'react-router-dom'
import Router from './Routes'
import axios from 'axios'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { useEffect, useState } from 'react'
import PageLoading from './components/Dumb/PageLoading/PageLoading'
import AlertsList from './components/Smart/AlertsList/AlertsList'
import { useDispatch, useSelector } from 'react-redux'
import { setFooter } from './stores/slices/footer'
import PopinInformation from './components/Smart/PopinInformation/PopinInformation'

export default function App() {
    // state
    const [isI18nLoaded, setIsI18nLoaded] = useState(false)

    // hook
    const dispatch = useDispatch()
    //store
    const currentUser = useSelector(state => state.customer.currentUser)
    const footer = useSelector(state => state.footer.content)
    // effect
    useEffect(() => {
        //get translations before initializing app
        axios.get('/api/translations').then(res => {
            const resources = res.data
            i18n
                // detect user language
                // learn more: https://github.com/i18next/i18next-browser-languageDetector
                // pass the i18n instance to react-i18next.
                .use(initReactI18next)
                // init i18next
                // for all options read: https://www.i18next.com/overview/configuration-options
                .init({
                    fallbackLng: 'fr',
                    react: { wait: true },
                    resources,
                })
            setIsI18nLoaded(true)
        })
    }, [])

    useEffect(() => {
        if (currentUser && !footer) {
            axios.get('/api/footer').then(res => {
                dispatch(setFooter(res.data.data))
            })
        }
    }, [currentUser])

    return (
        <>
            {isI18nLoaded && <PopinInformation />}
            <BrowserRouter>{isI18nLoaded ? <Router /> : <PageLoading />}</BrowserRouter>
            <AlertsList />
        </>
    )
}
