import { createSlice } from '@reduxjs/toolkit'

export const resetStateSlice = createSlice({
    name: 'resetState',
    initialState: { reset: false },
    reducers: {
        setResetState: (state, action) => {
            state.reset = action.payload
        },
    },
})

export const { setResetState } = resetStateSlice.actions

export default resetStateSlice.reducer
