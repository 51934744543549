/* eslint-disable */ // prevent error between .js and .jsx
// React and modules
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
// Components
import App from './App'
import store from './stores/store'
// Config
import './bootstrap'
import './interceptor'
import './services/errorHandler'

// Styles
import 'react-day-picker/dist/style.css'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
)
