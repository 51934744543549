export function localStorageGet(key) {
    return JSON.parse(window.localStorage.getItem(key))
}

export function localStorageSet(key, item) {
    window.localStorage.setItem(key, JSON.stringify(item))
    return item
}

export function localStorageRemember(key, item) {
    if (!localStorageGet(key)) {
        return localStorageSet(key, item)
    }

    return localStorageGet(key)
}

export function sessionStorageGet(key) {
    return JSON.parse(window.sessionStorage.getItem(key))
}

export function sessionStorageSet(key, item) {
    window.sessionStorage.setItem(key, JSON.stringify(item))
    return item
}

export function sessionStorageRemember(key, item) {
    if (!sessionStorageGet(key)) {
        return sessionStorageSet(key, item)
    }

    return sessionStorageGet(key)
}
