import { createSlice } from '@reduxjs/toolkit'
import { localStorageRemember, localStorageSet } from '../../services/localStorage'

const isMobile = () => window.innerWidth < 1040

const initialState = {
    sidebarNavIsExtend: localStorageRemember('sidebar_extend', !isMobile()),
    isMobile: isMobile(),
    displayLists: localStorageRemember('display_lists', 'list'),
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        updateSideBarNav: state => {
            state.sidebarNavIsExtend = localStorageSet('sidebar_extend', !state.sidebarNavIsExtend)
        },
        updateIsMobile: state => {
            state.isMobile = isMobile()
        },
        setDisplayLists: (state, action) => {
            state.displayLists = localStorageSet('display_lists', action.payload)
        },
    },
})

export const { updateSideBarNav, updateIsMobile, setDisplayLists } = layoutSlice.actions

export const toggleSideBarNav = () => dispatch => {
    dispatch(updateSideBarNav())
}

export default layoutSlice.reducer
