import { bool, func } from 'prop-types'
import React, { useState } from 'react'
import Popin from '../../Dumb/Popin/Popin'
import classNames from 'classnames'
import { useLanguageSelector } from '../../../services/languages'
import { useEffect } from 'react'
import { oneOfType } from 'prop-types'

export default function LanguageSelector({ collapse, callback }) {
    const [showLanguagesList, setShowLanguagesList] = useState(false)
    const [selectedLanguage, switchLanguage, languages] = useLanguageSelector()
    useEffect(() => {
        if (callback) callback(showLanguagesList)
    }, [showLanguagesList])
    return (
        <>
            <div
                className={classNames('flex items-center text-white text-sm cursor-pointer', {
                    'w-full justify-center': collapse,
                })}
                onClick={e => {
                    if (!showLanguagesList) {
                        e.stopPropagation()
                    }
                    setShowLanguagesList(true)
                }}
            >
                <img className='h-5 w-5 cursor-pointer' src='/assets/icons/globe.svg' />
                {!collapse && (
                    <span className='ml-2.5 first-letter-uppercase text-sm cursor-pointer'>
                        {languages.find(l => l.full_code === selectedLanguage.full_code)?.name ?? selectedLanguage.name}
                    </span>
                )}
            </div>

            <Popin open={showLanguagesList} setOpen={setShowLanguagesList}>
                <ul className='text-center px-3 text-iseki-grey-600'>
                    {languages.map((language, i) => (
                        <li
                            key={i + language.name + language.code}
                            onClick={e => {
                                e.stopPropagation()
                                // setSelectedLanguage(language)
                                switchLanguage(language)
                                setShowLanguagesList(false)
                            }}
                            className={`first-letter-uppercase p-2 rounded cursor-pointer transition-all ${
                                language.id === selectedLanguage.id
                                    ? 'bg-iseki-blue-500 text-white'
                                    : 'hover:bg-iseki-blue-500/60 hover:text-white'
                            }`}
                        >
                            {language.name}
                        </li>
                    ))}
                </ul>
            </Popin>
        </>
    )
}

LanguageSelector.propTypes = {
    /**
     * Collapse label, use it with <Nav /> isExtend feature.
     */
    collapse: bool,
    /**
     * callback function to give popin open status to parent
     */
    callback: oneOfType([bool, func]),
}
