import Button from '../../Dumb/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import SectionTitle from '../../Dumb/SectionTitle/SectionTitle'
import { useTranslation } from 'react-i18next'
import { alertError } from '../../../stores/slices/alerts'
import axios from 'axios'
import Checkbox from '../../Dumb/Checkbox/Checkbox'
import { removePopin } from '../../../stores/slices/popinInformation'

export default function PopinInformation() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const popin = useSelector(state => state.popinInformation?.popins?.[0] ?? null)
    const [validating, setValidating] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    const validate = () => {
        setValidating(true)

        if (!popin.previsu) {
            axios
                .post(`/api/popins/${popin.id}/consent`)
                .then(() => dispatch(removePopin(popin)))
                .catch(() => dispatch(alertError('Une erreur est survenue.')))
        } else {
            dispatch(removePopin(popin))
        }
    }

    useEffect(() => {
        setValidating(false)
        setConfirmed(false)
    }, [popin])

    useEffect(() => {
        if (confirmed) {
            setTimeout(validate, popin.previsu ? 500 : 0)
        }
    }, [confirmed])

    return (
        !!popin && (
            <div
                key={popin.id}
                className='w-screen h-screen bg-black bg-opacity-20 fixed flex items-center justify-center z-30 top-0 left-0 z-50'
            >
                <div className={`bg-white w-11/12 md:w-1/2 xl:w-1/3 p-8 rounded-md relative`}>
                    <SectionTitle title={popin.titre} />

                    <div
                        className={'mt-4 page-cms-renderer'}
                        dangerouslySetInnerHTML={{
                            __html: popin.description,
                        }}
                    />

                    {!!popin.url && !!popin.url_label && (
                        <div className={'mt-2'}>
                            <a
                                href={popin.url}
                                target='_blank'
                                className={'text-iseki-blue-400 underline'}
                                rel='noreferrer'
                            >
                                {popin.url_label}
                            </a>
                        </div>
                    )}

                    {!!popin.confirm && (
                        <div className={'mt-10'}>
                            <Checkbox
                                label={t('messages.popin.confirm')}
                                isChecked={confirmed}
                                setIsChecked={setConfirmed}
                                disabled={validating}
                            />
                        </div>
                    )}

                    {!popin.confirm && (
                        <div className='flex justify-end mt-6'>
                            <Button
                                onClick={validate}
                                text={t('messages.popin.validate')}
                                size='tiny'
                                className='mr-2'
                                disabled={(!!popin.confirm && !confirmed) || validating}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    )
}
